import { LayersControl, TileLayer as LeafletTileLayer } from 'react-leaflet';
import Constants from '../../../constants';
import { useBasemap } from '../../../store/Map/Basemap/use-basemap';
import { Basemap } from '../../../store/Map/Basemap/model';
import {
    ProtomapsTileLayer,
    SOAR_OSM_URL,
    SOAR_DARK,
    SOAR_LIGHT,
    OSM_LIGHT,
    OSM_DARK,
    OSM_WHITE,
    OSM_BLACK,
    OSM_GRAYSCALE,
} from '../../MapView/TileLayers/ProtoMapTileLayers/protomaps-tile-layer';

const MobileBasemap = () => {
    const { basemap } = useBasemap();
    const OSM_ATTRIBUTION = Constants.MAPS_ATTRIBUTION.OSM_ATTRIBUTION;

    return (
        <LayersControl position="bottomright">
            <LayersControl.BaseLayer name={Basemap.OPEN_ELEVATION} checked={basemap === Basemap.OPEN_ELEVATION}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={13} url={Constants.MAPS.OSM_TOPO} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.OPEN_STREETMAPS} checked={basemap === Basemap.OPEN_STREETMAPS}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={19} url={Constants.MAPS.OSM_STREET} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.GOOGLE_SATELLITE} checked={basemap === Basemap.GOOGLE_SATELLITE}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={Constants.MAPS.GOOGLE_SATELLITE} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.GOOGLE_HYBRID} checked={basemap === Basemap.GOOGLE_HYBRID}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={Constants.MAPS.GOOGLE_HYBRID} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.GOOGLE_STREET} checked={basemap === Basemap.GOOGLE_STREET}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={Constants.MAPS.GOOGLE_STREET} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.DARK_BLANK} checked={basemap === Basemap.DARK_BLANK}>
                <LeafletTileLayer url="/assets/basemap/black-pixel.png" />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.WHITE_BLANK} checked={basemap === Basemap.WHITE_BLANK}>
                <LeafletTileLayer url="/assets/basemap/white-pixel.png" />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.OSM_SOAR_DARK} checked={basemap === Basemap.OSM_SOAR_DARK}>
                <ProtomapsTileLayer url={SOAR_OSM_URL} attribution={OSM_ATTRIBUTION} paintRules={SOAR_DARK} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.OSM_SOAR_LIGHT} checked={basemap === Basemap.OSM_SOAR_LIGHT}>
                <ProtomapsTileLayer url={SOAR_OSM_URL} attribution={OSM_ATTRIBUTION} paintRules={SOAR_LIGHT} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.OSM_DEFAULT_LIGHT} checked={basemap === Basemap.OSM_DEFAULT_LIGHT}>
                <ProtomapsTileLayer url={SOAR_OSM_URL} attribution={OSM_ATTRIBUTION} paintRules={OSM_LIGHT} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.OSM_DEFAULT_DARK} checked={basemap === Basemap.OSM_DEFAULT_DARK}>
                <ProtomapsTileLayer url={SOAR_OSM_URL} attribution={OSM_ATTRIBUTION} paintRules={OSM_DARK} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.OSM_DEFAULT_WHITE} checked={basemap === Basemap.OSM_DEFAULT_WHITE}>
                <ProtomapsTileLayer url={SOAR_OSM_URL} attribution={OSM_ATTRIBUTION} paintRules={OSM_WHITE} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.OSM_DEFAULT_BLACK} checked={basemap === Basemap.OSM_DEFAULT_BLACK}>
                <ProtomapsTileLayer url={SOAR_OSM_URL} attribution={OSM_ATTRIBUTION} paintRules={OSM_BLACK} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer
                name={Basemap.OSM_DEFAULT_GRAYSCALE}
                checked={basemap === Basemap.OSM_DEFAULT_GRAYSCALE}
            >
                <ProtomapsTileLayer url={SOAR_OSM_URL} attribution={OSM_ATTRIBUTION} paintRules={OSM_GRAYSCALE} />
            </LayersControl.BaseLayer>
        </LayersControl>
    );
};

export default MobileBasemap;
