export enum Basemap {
    OPEN_ELEVATION = 'Open Elevation',
    GOOGLE_SATELLITE = 'Google Satellite',
    GOOGLE_HYBRID = 'Google Hybrid',
    GOOGLE_STREET = 'Google Street',
    OPEN_STREETMAPS = 'OpenStreetMaps',
    OSM_SOAR_DARK = 'OSM Soar Dark',
    OSM_SOAR_LIGHT = 'OSM Soar Light',
    OSM_DEFAULT_LIGHT = 'OSM Light',
    OSM_DEFAULT_DARK = 'OSM Dark',
    OSM_DEFAULT_WHITE = 'OSM White',
    OSM_DEFAULT_BLACK = 'OSM Black',
    OSM_DEFAULT_GRAYSCALE = 'OSM Grayscale',
    DARK_BLANK = 'Dark Blank',
    WHITE_BLANK = 'White Blank',
}

export const getBasemap = (value: string): Basemap | undefined => {
    const key = Object.keys(Basemap).find((key) => Basemap[key] === value);
    if (!key) return undefined;
    return Basemap[key];
};
